export default (theme) => {
  const heroVerticalPadding = '3rem'

  return {
    pageTitle: {
      textAlign: 'center',
      margin: theme.spacing(4, 2),
      fontWeight: 'bold',
    },

    iframe: {
      margin: '0 auto',
      display: 'flex',
      width: '95%',
      height: '100vh',
      border: 'none',
      marginBottom: theme.spacing(3),
      boxShadow: '0px 4px 7px 2px rgba(101, 101, 101, 0.3)',
      borderRadius: '10px',
    },

    headerText: {
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'center',
      padding: `${heroVerticalPadding} 0`,
    },
  }
}
