import { Typography } from '@pickupp/ui/core'
import { makeStyles } from '@pickupp/ui/styles'
import qs from 'qs'
import React, { useContext } from 'react'

import { BROWSER, MERCHANT_PORTAL } from '~/config'
import Page from '~/src/components/Page'
import { LocaleContext } from '~/src/contexts'
import { useIsMobile, useTranslation } from '~/src/hooks'

import styles from './styles'

const useStyles = makeStyles(styles)

const SelfPickupStoreSearchPage = () => {
  const classes = useStyles()

  const { language } = useContext(LocaleContext)
  const isMobile = useIsMobile()
  const t = useTranslation()

  const paramsString = BROWSER ? window.location.search.substring(1) : ''
  const params = qs.parse(paramsString)
  const region = params.region || 'hk'
  const lat = params.pickupp_lat || 0
  const lng = params.pickupp_lng || 0
  const selfPickupStoreSearchPage = `${MERCHANT_PORTAL}/${language}/self-pickup-store?region=${region}&lat=${lat}&lng=${lng}`

  return (
    <Page>
      <Typography variant="h3" className={classes.pageTitle}>
        {t('publicSelfPickupStorePage.mainTitle')}
      </Typography>
      <iframe
        title="Self Pickup Store Search"
        scrolling={isMobile}
        src={selfPickupStoreSearchPage}
        className={classes.iframe}
        allowFullScreen
      />
    </Page>
  )
}

export default SelfPickupStoreSearchPage
